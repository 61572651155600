<template>
  <div
    class="tab-pane fade"
    id="pills-transactionsLog"
    role="tabpanel"
    aria-labelledby="pills-transactionsLog-tab"
  >
    <div class="ma-table">
      <div class="ma-thead">
        <div v-for="(text, i) in thead_texts" :key="i" class="thead-child">
          {{ text }}
        </div>
      </div>

      <hr style="border: 0.6px solid #e5e5e5; width: 300%" />
      <div
        v-if="sageTransactionsData.data && sageTransactionsData.data.length > 0"
      >
        <div v-for="(item, i) in sageTransactionsData.data" :key="i">
          <div class="ma-tbody">
            <div style="word-wrap: break-word" class="tbody-child">
              {{ item.data_set_name.replace(/_/g, " ") }}
            </div>
            <div class="tbody-child">
              {{ item.source_account_number }}
            </div>
            <div class="tbody-child">
              {{ getProductName(item.product_id) }}
            </div>
            <div class="tbody-child">
              {{ item.destination_account_number }}
            </div>
            <div class="tbody-child">
              {{ item.document_number }}
            </div>
            <div class="tbody-child">
              {{ addCommas(item.amount) }}
            </div>
            <div>
              <div class="tbody-child text-success" v-if="item.status">
                True
              </div>
              <div v-else class="tbody-child text-danger">False</div>
            </div>
            <div class="tbody-child">
              {{ item.is_deleted }}
            </div>
            <div class="tbody-child" style="word-break: break-word">
              {{
                item.delete_reason ? item.delete_reason.replace(/_/g, " ") : ""
              }}
            </div>
            <div class="tbody-child" style="word-break: break-word">
              {{ item.description.replace(/_/g, " ") }}
            </div>
            <div class="tbody-child">
              {{ item.posting_channel }}
            </div>
            <div class="tbody-child" style="word-break: break-word">
              {{
                item.error_message
                  ? item.error_message.replace(/[^a-zA-Z 0-9]+/g, " ")
                  : ""
              }}
            </div>
            <div class="tbody-child">
              {{ item.date_created | moment }}
            </div>
            <div class="tbody-child">
              {{ item.date_modified | moment }}
            </div>
            <div class="tbody-child">
              {{ item.date_deleted | moment }}
            </div>
          </div>

          <hr style="border: 0.6px solid #e5e5e5; width: 300%" />
        </div>
      </div>

      <div v-else-if="setLoading && !sageTransactionsData.data">
        <div class="d-flex align-items-center justify-content-center p5">
          <div
            class="spinner-border"
            style="width: 3rem; height: 3rem"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <span class="text--black text--600 text--medium d-inline-flex ml-4"
            >Please wait</span
          >
        </div>
      </div>

      <div v-else>
        <div
          class="d-flex flex-column align-items-center justify-content-center p5"
        >
          <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

          <span class="d-block text--danger text--600 text--medium mt-3 mb-5">
            No data available</span
          >
        </div>
      </div>
    </div>
    <Pagination
      v-if="sageTransactionsData.data && sageTransactionsData.data.length > 0"
      :allItems="sageTransactionsData"
      :size="parameters.pagesize"
      @update-created-task="changeLength"
      @change-page-index="changeCurrentPage"
    />
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "FinanceLog",
  props: ["sageTransactionsData", "loading", "allProducts"],
  data() {
    return {
      thead_texts: [
        "Dataset Name",
        "Source Acc. Number",
        "Product Name",
        "Destination Acc. Number",
        "Document Number",
        "Amount",
        "Status",
        "Deleted",
        "Reason Deleted",
        "Description",
        "Posting Channel",
        "Error Message",
        "Date Created",
        "Date Modified",
        "Date Deleted",
      ],
      parameters: {
        pageindex: 1,
        pagesize: 20,
        SortDescending: true,
        status: null,
        CreationDateFrom: null,
        CreationDateTo: null,
        SortColumn: null,
      },
    };
  },
  components: {
    Pagination: () =>
      import(
        /* WebpackChunkName: "Pagination" */ "@/components/UI/pagination.vue"
      ),
  },
  methods: {
    getProductName(id) {
      let prodName = "";

      if (id) {
        prodName = this.allProducts.find(
          (item) => item.id === id || item.loanProductKey === id
        )?.productName;
      }

      return prodName;
    },

    addCommas(amount) {
      let currency = `\u20A6`;
      if (amount) {
        let fixedAmount = amount.toFixed(2);
        return `${currency}${fixedAmount
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
      } else {
        return `${currency}0.00`;
      }
    },

    changeCurrentPage(pages = null, pageSize) {
      if (pages === "next") {
        this.parameters.pageindex++;
        this.parameters.pagesize = pageSize;
        this.$emit("getTransData", this.parameters);
      } else if (pages === "previous") {
        this.parameters.pageindex--;
        this.parameters.pagesize = pageSize;
        this.$emit("getTransData", this.parameters);
      } else if (pages !== null) {
        this.parameters.pageindex = pages;
        this.parameters.pagesize = pageSize;
        this.$emit("getTransData", this.parameters);
      }
    },

    changeLength(value) {
      this.parameters.pagesize = value;
      this.$emit("getTransData", this.parameters);
    },
  },
  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },
  computed: {
    setLoading() {
      return this.loading;
    },
  },
};
</script>

<style scoped>
.head-text {
  font-weight: 600;
  font-size: 1.8rem;
  font-family: "Montserrat-semibold";
  line-height: 32px;
  color: #1e1f20;
  text-transform: capitalize;
  margin-bottom: 5px;
}
.box {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.download-box {
  width: 185px;
  height: 40px;
  background: #e9e7e7;
  border-radius: 5px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 32px;
  color: #000000;
  text-align: center;
}
.btn-black {
  width: 185px;
  height: 40px;
  background: #000000;
  border-radius: 5px;
  border: 1px solid #000000;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 37px;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}
.dash-box {
  width: 75%;
  /* border: 1px dashed #c4c4c4; */
  box-sizing: border-box;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 15px;
}
.hid-box {
  width: 25%;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.thead-child {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #9596a2;
  width: 150px;
  /* text-align: flex-start; */
}
.tbody-child {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: #000000;
  width: 150px;
  align-items: center;
  /* text-align: flex-start; */
  padding-top: 10px;
  padding-bottom: 10px;
}
.ma-thead,
.ma-tbody {
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(15, 1fr) !important;
  /* display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px; */
}
.ma-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: scroll;
}
.action {
  width: 95px;
  height: 35px;
  background: #e9e7e7;
  border-radius: 5px;
  border: 1px dashed #e9e7e7;
  font-weight: 500;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.action-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.action-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.disable-btn {
  background: #e9e7e7;
  width: 185px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #e9e7e7;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 37px;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}
input[type="file"] {
  display: none;
}
</style>
